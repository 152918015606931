import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";


export const Calendar = memo((props) => {
    const {t, i18n} = useTranslation();
    return (
        <FullCalendar
            plugins={[dayGridPlugin, bootstrap5Plugin, interactionPlugin]}
            initialView="dayGridMonth"
            locale={i18n.language}
            headerToolbar={{
                left: "title",
                right: "today prev,next"
            }}
            navLinks={false}
            editable={false}
            dayMaxEvents={false}
            eventResizableFromStart={true}
            firstDay={1}
            buttonText={t("calendarBtnText", {returnObjects: true})}
            themeSystem={"bootstrap5"}
            height={650}
            aspectRatio={6}
            {...props}
        />
    );
});
