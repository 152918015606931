import React from "react";
import {Col, Image, Row} from "react-bootstrap";

export const ImagesTop = ({images = []}) => {
    return (
        <>
            <Row>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Image className={"p-2 ps-lg-0"} width={"100%"} height={"100%"} src={images[3]}/>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Image className={"p-2 pe-lg-0"} width={"100%"} height={"100%"} src={images[1]}/>
                </Col>
            </Row>
            <Row>
                <Col xs={{span: 12}} lg={{span: 4}}>
                    <Image className={"p-2 ps-lg-0"} width={"100%"} height={"100%"} src={images[4]}/>
                </Col>
                <Col xs={{span: 12}} lg={{span: 4}}>
                    <Image className={"p-2"} width={"100%"} height={"100%"} src={images[0]}/>
                </Col>
                <Col xs={{span: 12}} lg={{span: 4}}>
                    <Image className={"p-2 pe-lg-0"} width={"100%"} height={"100%"} src={images[2]}/>
                </Col>
            </Row>
        </>
    );
};
