import React from "react";
import {DropdownButton, Navbar, Dropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import {loadState} from "../../helpers/localStorage";


const Langs = () => {
    const {i18n} = useTranslation();

    return (
        <Navbar.Collapse>
            <Navbar.Text>
                {
                    i18n.language === "uk" ?
                        <a className={"nav-link text-white"} onClick={() => i18n.changeLanguage("de")}>
                            DE <i className={"cu-icon__xs cu-de"}/>
                        </a> :
                        <a className={"nav-link text-white"} onClick={() => i18n.changeLanguage("uk")}>
                            UA <i className={"cu-icon__xs cu-ua"}/>
                        </a>
                }
            </Navbar.Text>
        </Navbar.Collapse>
    )
}

export const Header = () => (
    <HeaderTemplate fixed={"top"}>
        <Navbar.Text>
            <a className={"nav-link text-white"} href="mailto:mail@marcus-i-weber.de">
                MAIL@MARCUS-I-WEBER.DE
            </a>
        </Navbar.Text>
    </HeaderTemplate>
);


export const HeaderAdmin = () => {
    const {t} = useTranslation();
    const auth = loadState("SESSION");

    return (
        <HeaderTemplate sticky={"top"} className={"bg-primary"}>
            {
                auth ? (
                    <DropdownButton align="end" title={t("menu")} className={"me-3"}>
                        <Dropdown.Item>
                            <Navbar.Text>
                                <Link className={"nav-link"} to={"/booking"}>
                                    <i className="bi bi-calendar-event pe-2"/> {t("booking")}
                                </Link>
                            </Navbar.Text>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Navbar.Text>
                                <Link className={"nav-link"} to={"/price"}>
                                    <i className="bi bi-tags pe-2"/> {t("price")}
                                </Link>
                            </Navbar.Text>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Navbar.Text>
                                <Link className={"nav-link"} to={"/"}>
                                    <i className="bi bi-house pe-2"/>{t("home")}
                                </Link>
                            </Navbar.Text>
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item>
                            <Navbar.Text>
                                <Link className={"nav-link"} to={"/logout"}>
                                    <i className="bi bi-box-arrow-right pe-2"/> {t("logout")}
                                </Link>
                            </Navbar.Text>
                        </Dropdown.Item>
                    </DropdownButton>
                ) : (
                    <Navbar.Text>
                        <Link className={"nav-link text-white"} to={"/"}>
                            <i className="bi bi-house pe-2"/>{t("home")}
                        </Link>
                    </Navbar.Text>
                )
            }
        </HeaderTemplate>
    );
};


const HeaderTemplate = (props) => {
    return (
        <Navbar {...props}>
            <Langs/>
            <Navbar.Collapse className={"justify-content-end"}>
                {
                    props.children
                }
            </Navbar.Collapse>
        </Navbar>
    )
}