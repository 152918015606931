import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import paella from "../../assets/paella.webp";
import "./Loading.scss";


export const Loading = () => {
    const {t} = useTranslation();
    return (
        <div className={"paella-background"}>
            <img className={"paella"} src={paella} alt=""/>
            <h4 className={"paella-title loading"}>{t("loading")}</h4>
        </div>
    );
};

export const Paella = () => {
    const {t} = useTranslation();

    return (
        <div className={"paella-background"}>
            <h1 className={"paella-title"}>me gusta la paella ❤️</h1>
            <img className={"paella"} src={paella} alt=""/>
            <Link className={"paella-link"} to="/">{t("back")}</Link>
        </div>
    );
};