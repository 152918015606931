import React, {memo} from "react";


export const Map = memo(() => {
    return (
        <div className={"map pt-4"}>
            <iframe width={'100%'} height={'100%'} loading={'lazy'}
                    src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2751.088386103835!2d-4.718008841719702!3d36.50137015918589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd382e1e37a5880c9!2zMzbCsDMwJzAxLjIiTiA0wrA0Mic1Ny4xIlc!5e1!3m2!1sde!2sde!4v1644935147330!5m2!1sde!2sde'}
            />
        </div>
    );
});
