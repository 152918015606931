import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import de from "../locale/de.json"
import uk from "../locale/uk.json"

const resources = {
    de: {
        translation: de
    },
    uk: {
        translation: uk
    },
};

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.i18nextLng || "de",
        interpolation: {
            escapeValue: false,
        },
    });

