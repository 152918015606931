import React, {memo} from "react";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import uniqid from "uniqid";

// import "swiper/css/lazy";
import "swiper/css";


export const Slider = memo(({images = []}) => {
    return (
        <Swiper
            grabCursor={true}
            pagination={{clickable: true}}
            navigation={true}
            modules={[Navigation, Pagination]}
        >
            {
                images.map(img =>
                    <SwiperSlide key={uniqid()}><img src={img} alt=""/></SwiperSlide>
                )
            }
        </Swiper>
    );
});
