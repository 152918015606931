import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {ReactNotifications} from 'react-notifications-component'

import "./helpers/i18n"
import {RouterApp, Loading} from "./components";

import "@fontsource/montserrat";
import "swiper/css/bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import "./index.scss";


ReactDOM.render(
    <Suspense fallback={<Loading/>}>
        <ReactNotifications/>
        <div className="App">
            <RouterApp/>
        </div>
    </Suspense>,
    document.getElementById("root")
);
