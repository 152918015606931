import React, {useRef, useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";


export const AddEventModal = ({show, state, onHide, addEvent, updateEvent, deleteEvent}) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const {t} = useTranslation();
    const titleRef = useRef();
    const startRef = useRef();
    const endRef = useRef();
    const colorRef = useRef();
    const showRef = useRef();

    const colors = [
        '#0d34fd',
        '#a10dfd',
        '#fd0d31',
        '#0dd1fd',
        '#fd550d',
        '#39fd0d',
        '#fdb90d',
    ]

    const submitHandler = (e) => {
        e.preventDefault()
        const event = {
            id: state.id,
            title: titleRef.current.value,
            start: startRef.current.value,
            end: endRef.current.value,
            color: colorRef.current.value,
            show: showRef.current.checked
        }
        state.id ? updateEvent(event) : addEvent(event)
    }

    const onHideDeleteDialog = () => {
        setShowDeleteDialog(false);
    }

    const onShowDeleteDialog = () => {
        setShowDeleteDialog(true);
    }

    const onDelete = () => {
        setShowDeleteDialog(false);
        deleteEvent(state.id)
    }

    return (
        <>
            <Modal
                show={show}
                size="lg"
                centered
                onHide={onHide}
                style={{zIndex: 1050}}
            >
                <Form onSubmit={submitHandler}>
                    <Modal.Header>
                        <Modal.Title>
                            {t("addEvent")}
                        </Modal.Title>
                        {
                            state.id && (
                                <Button onClick={onShowDeleteDialog} className={"btn-danger"}>
                                    {t("deleteEvent")}
                                </Button>
                            )
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"row"}>
                            <Form.Group className="mb-3 col-8">
                                <Form.Label>{t("title")}</Form.Label>
                                <Form.Control
                                    ref={titleRef}
                                    type="text"
                                    defaultValue={state.title}
                                    placeholder={t("enterEventTitle")}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-2">
                                <Form.Label>{t("color")}</Form.Label>
                                <Form.Control
                                    ref={colorRef}
                                    type="color"
                                    defaultValue={state.color || colors[Math.floor(Math.random() * 7)]}
                                    title={t("chooseEventColor")}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-2">
                                <Form.Label>{t("show")}</Form.Label>
                                <Form.Check
                                    ref={showRef}
                                    type="switch"
                                    defaultChecked={state.show === undefined ? true : state.show}
                                />
                            </Form.Group>
                        </div>

                        <div className={"row"}>
                            <Form.Group className="mb-3 col-6">
                                <Form.Label>{t("startDate")}</Form.Label>
                                <Form.Control
                                    ref={startRef}
                                    type="date"
                                    defaultValue={state.start}
                                    title={t("chooseStartDate")}
                                    placeholder="dd-mm-yyyy"
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-6">
                                <Form.Label>{t("endDate")}</Form.Label>
                                <Form.Control
                                    ref={endRef}
                                    type="date"
                                    defaultValue={state.end}
                                    title={t("chooseEndDate")}
                                    placeholder="dd-mm-yyyy"
                                    required
                                />
                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide}>
                            {t("close")}
                        </Button>

                        <Button type={"submit"} className={"btn-success"}>
                            {
                                state.id ? t("updateEvent") : t("addEvent")
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal
                show={showDeleteDialog}
                size="sm"
                centered
                onHide={onHideDeleteDialog}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t("deleteEvent")}?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={onHideDeleteDialog}>
                        {t("no")}
                    </Button>

                    <Button onClick={onDelete} className={"btn-danger"}>
                        {t("yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
