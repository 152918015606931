import React from "react"
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";


export const Footer = () => {
    const {t} = useTranslation();
    return (
        <footer className="page-footer font-small mt-5">
            <div className="container-fluid text-center text-md-left">
                <Row>
                    <Col lg={{offset: 3, span: 6}} xs={{offset: 1, span: 10}} className="mt-md-0 mt-3">
                        <h5 className="text-uppercase">Holiday & Home</h5>
                        <p className={"welcomeFooter"} dangerouslySetInnerHTML={{
                            __html: t("welcomeFooter", {paellaLink: "<a href='/paella'> ❤️</a>"})
                        }}/>
                    </Col>
                </Row>
            </div>

            <div className="footer-copyright text-center py-3">
                © 2022 Copyright: <a href="https://github.com/UnderMountain96" target="_blank" rel="nofollow">
                UnderMountain</a>
            </div>
        </footer>
    );
}
