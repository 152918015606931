import React, {lazy} from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

import {loadState, removeState} from "../../helpers/localStorage";
import {Paella} from "../../components";


const Home = lazy(() => import("../../views/Home/Home"));
const Booking = lazy(() => import("../../views/Booking/Booking"));
const Price = lazy(() => import("../../views/Price/Price"));
const Login = lazy(() => import("../../views/Login/Login"));


export const RouterApp = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="/booking" element={<RequireAuth element={<Booking/>}/>}/>
                <Route path="/price" element={<RequireAuth element={<Price/>}/>}/>
                <Route path="/paella" element={<Paella/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </BrowserRouter>
    );
};

const Logout = () => {
    removeState("SESSION")

    return <Navigate to="/login"/>
}

const RequireAuth = ({element, children}) => {
    const auth = loadState("SESSION");

    if (!auth) {
        return <Navigate to="/login"/>;
    }

    return element || children;
};